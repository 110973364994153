import React from 'react';
import PropTypes from 'prop-types';
import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import Button from 'common/src/components/Button';
import Input from 'common/src/components/Input';
import Container from 'common/src/components/UI/Container';

import NewsletterWrapper, { ContactFormWrapper } from './newsletter.style';

const Newsletter = ({
  sectionWrapper,
  textArea,
  buttonArea,
  buttonStyle,
  title,
  description,
}) => {
  return (
    <Box {...sectionWrapper} as="section" id="newsletter">
      <Container>
        <NewsletterWrapper>
          <Box {...textArea}>
            <Heading content="Stay updated with the latest news" {...title} />
            <Text
              content="We will subscribe you to our news letter. You will be aware of our progress ! "
              {...description}
            />
          </Box>
          <Box {...buttonArea}>
            <iframe width="540" 
            height="800" 
            src="https://29b38a68.sibforms.com/serve/MUIEAEYx7EI3sU0hkGgJku6FYPhtRLkVA40QBQvDaIfcDmSd5zii7mu3q1MWari3BgK_PrjobxT-i6mPR1pitB-ic5wsfEmd10LngAQ3_d5g7qJEhEmGsOa50JdH5LxInz3JpQ-Vh11auq9ysOucOxQ3-sjPmh7f5KKActo842fT4mDQ8HHm2BQ61MveQkpzyKydWzk6vz5Dcm_7" 
            frameBorder="0" 
            scrolling="auto" 
            allowFullScreen 
            style={{display: 'block', marginLeft: 'auto', marginRight: 'auto', maxWidth: '100%'}}></iframe>
          </Box>
        </NewsletterWrapper>
      </Container>
    </Box>
  );
};

Newsletter.propTypes = {
  sectionWrapper: PropTypes.object,
  textArea: PropTypes.object,
  buttonArea: PropTypes.object,
  buttonStyle: PropTypes.object,
  title: PropTypes.object,
  description: PropTypes.object,
};

Newsletter.defaultProps = {
  sectionWrapper: {},
  textArea: {
    mb: ['40px', '40px', '40px', '0', '0'],
    pr: ['0', '0', '0', '80px', '100px'],
  },
  title: {
    fontSize: ['18px', '20px', '22px', '24px', '26px'],
    fontWeight: '500',
    color: '#fff',
    lineHeight: '1.34',
    mb: ['14px', '14px', '14px', '14px', '13px'],
    textAlign: ['center', 'center', 'center', 'left', 'left'],
    letterSpacing: '-0.025em',
  },
  description: {
    fontSize: ['14px', '14px'],
    maxWidth: ['100%', '400px'],
    fontWeight: '400',
    color: '#fefefe',
    lineHeight: '1.7',
    mb: 0,
    textAlign: ['center', 'center', 'center', 'left', 'left'],
  },
  buttonArea: {
    zIndex: 1,
    width: ['100%', 'auto'],
  },
  buttonStyle: {
    type: 'button',
    fontSize: '14px',
    fontWeight: '700',
    pl: '30px',
    pr: '30px',
  },
};

export default Newsletter;
